import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropdownDonViOneComponent } from './dropdown-donvi-one/dropdown-donvi-one.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InputHtmlComponent } from './input-html/input-html.component';
import { ViewImageComponent } from './view-image/view-image/view-image.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { FileListComponent } from './file-list/file-list.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SpanCurrencyComponent } from './span-currency/span-currency.component';
import { InputCMNDComponent } from './input-cmnd/input-cmnd.component';
import { BtnSaveComponent } from './button/btn-save/btn-save.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FileImportComponent } from './file-import/file-import.component';
import { FileUploadResidentComponent } from './file-upload-resident/file-upload-resident.component';
import { InputCurrencyComponent } from './input-currency/input-currency.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { ImageInTableMultiComponent } from './image-components/image-in-table-multi/image-in-table-multi.component';
import { ImageShowMultiComponent } from './image-components/image-show-multi/image-show-multi.component';
const antdModule = [
  NzButtonModule,
  NzModalModule,
  NzUploadModule,
  NzDatePickerModule,
  NzImageModule,
  NzIconModule,
];
@NgModule({
  declarations: [
    FileUploadComponent,
    DatePickerComponent,
    DropdownDonViOneComponent,
    InputHtmlComponent,
    ViewImageComponent,
    FileListComponent,
    InputNumberComponent,
    SpanCurrencyComponent,
    InputCMNDComponent,
    BtnSaveComponent,
    FileImportComponent,
    FileUploadResidentComponent,
    InputCurrencyComponent,
    ImageInTableMultiComponent,
    ImageShowMultiComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    OverlayModule,
    NgSelectModule,
    CKEditorModule,
    ...antdModule,
    NgxMaskDirective,
    NgxMaskPipe,
    GalleryModule.withConfig({
      disableThumb: true,
    }),
    LightboxModule.withConfig({
      panelClass: 'fullscreen',
    }),
    InputMaskModule
  ],
  providers: [provideNgxMask()],
  exports: [
    FileUploadComponent,
    DatePickerComponent,
    DropdownDonViOneComponent,
    InputHtmlComponent,
    ViewImageComponent,
    FileListComponent,
    InputNumberComponent,
    SpanCurrencyComponent,
    InputCMNDComponent,
    BtnSaveComponent,
    FileImportComponent,
    FileUploadResidentComponent,
    InputCurrencyComponent,
    ImageInTableMultiComponent,
  ],
})
export class ComponentsModule { }
