export const environment = {
  production: true,
  ACCOUNT_API: 'https://prodapi.chienbinhecopark.com/auth/',
  SERVICE_API_MAIN: 'https://prodapi.chienbinhecopark.com/main/',
  SERVICE_API_TRAIN: 'https://prodapi.chienbinhecopark.com/train/',
  SERVICE_API_FILE: 'https://prodapi.chienbinhecopark.com/data/',
  SERVICE_NOTI_API: 'https://prodapi.chienbinhecopark.com/noti/',
  SERVICE_API_LOG: 'https://prodapi.chienbinhecopark.com/log/',
  SERVICE_API_SCANNER: 'https://prodapi.chienbinhecopark.com/scanner/',
  // SERVICE_API_BUS: 'https://api.chienbinhecopark.com/scanbus/',
  SERVICE_NEW_API: 'https://prodapi.chienbinhecopark.com/mxh/',
  SERVICE_API_EVENTS: 'https://prodapi.chienbinhecopark.com/event/',
  SERVICE_API_ROUTING: 'https://prodapi.chienbinhecopark.com/routing/',
  RECAPTCHA_SITEKEY: '6LdV8tImAAAAAKBq2BykmT-3S9-XTlLWNEiXMWUg',
  COOKIE_PREFIX: 'pro',
  MA_APP: 'ECS',
  fireBaseConfig: {
    apiKey: "AIzaSyCfZXaTZOozZDhLkMnpHwt2cLZnwUYykS0",
    authDomain: "sandboxvn-ecosales.firebaseapp.com",
    projectId: "sandboxvn-ecosales",
    storageBucket: "sandboxvn-ecosales.appspot.com",
    messagingSenderId: "935293297914",
    appId: "1:935293297914:web:17a5591f01e1d9bd5f5543",
    measurementId: "G-EF8G7TH6SB"
  },
  vapidKey: 'BNcPByv_fqbRa5fCqegzgyH3NNybGSbmwdFil0N0UfiY3gIz_xwohP1dgJLzc48wvuEQUhwrD-UxxmNuO1HVTmM'
};

