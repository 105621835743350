import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../base.service';
import { UserLogin } from '../../models/userlogin';
import { StorageService } from '../storage.service';
import { ResponseData } from 'src/app/models/response';
import { map, Observable, of, Subject } from 'rxjs';
import {tap} from "rxjs/operators";
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService {

  public static currentUser: UserLogin;

  constructor(
    public httpClient: HttpClient,
    private storageService: StorageService,
  ) {
    super(httpClient, `${environment.ACCOUNT_API}api/User`)
  }

  // getUserLogin(): UserLogin {

  //   var user = this.storageService.get(STORAGE_KEY.USER_INFO);
  //   if (user) {
  //     return new UserLogin(user);
  //   } else {
  //     return new UserLogin({})
  //   }
  //   // var token = this.storageService.get(STORAGE_KEY.ACCESS_TOKEN);
  //   // var decoded = jwt_decode(token);
  //   // if(decoded && decoded["userinfo"]){
  //   //   var userInfo = decoded["userinfo"];// decodeURIComponent(escape(window.atob( decoded["userinfo"] )));
  //   //   return new UserLogin(JSON.parse(userInfo));
  //   // }
  //   // return new UserLogin({})
  // }

  resetTopic(body) : Observable<ResponseData> {
    const url = `${this.table}/ResetTopic`;
    return this.post<ResponseData>(url, body)
  }

  

  getUserInfo(): Promise<ResponseData> {
    const url = `${this.table}/GetUserInfo`;
    return this.post<ResponseData>(url, {}).toPromise();
  }

  ClearCurrentUser(){
    if(UserService.currentUser){
      UserService.currentUser = null;
    }
  }

  getCurrentUser():Observable<UserLogin>{
   
   
    if(UserService.currentUser){
        return of(UserService.currentUser);
    }else{

    const url = `${environment.ACCOUNT_API}api/UserProfile/GetUserInfo`;
    return this.post<ResponseData>(url, {}).pipe(
        tap((res : ResponseData) => {
          UserService.currentUser = res.data;
        }),
        map((res : ResponseData) => {
          if(res.success){
            return res.data;
          }else{
            return null;
          }
          
        })
        );
    }
  }


}

