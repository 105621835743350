export const STORAGE_KEY = {
  ACCESS_TOKEN: 'access_token',
  ACCESS_TOKEN_REFRESH: 'refresh_token',
  ROLE: 'role',
  USER_INFO: 'user_info',
  VT_TRINH_VB_DEN: 'vt_trinh_vb_den',
  VT_TRINH_VB_DI: 'vt_trinh_vb_di',
};

export const STATUS_CODE = {
  SUCCESS: 200,
  ERROR: 201,
  UNAUTHORIZED: 401,
};

export const API_STATUS = {
  SUCCESS: 1,
  ERROR: 0,
};

export const SYSTYPE_CONFIRM = {
  BE: 'Trong hệ thống',
  FE: 'Ngoài hệ thống',
};

export const DOCUMENT_TYPE_ID = {
  VAN_BAN_DEN: '1',
  VAN_BAN_DI: '2',
  VAN_BAN_DUTHAO: '3',
  TO_TRINH: '4',
  VAN_BAN_NOIBO: '5',
};

export const TYPE_MODAL = {
  CONG_BO: '1',
  XIN_Y_KIEN: '2',
};

export const LIST_LEVELSALE: any[] = [
  {
    id: 1,
    ten: 'Sale Cộng Tác Viên',
    text: 'Sale Cộng Tác Viên',
  },
  {
    id: 2,
    ten: 'Sale Chính Thức EcoCentralPark',
    text: 'Sale Chính Thức EcoCentralPark',
  },
  {
    id: 3,
    ten: 'Sale VIP',
    text: 'Sale VIP',
  },
  {
    id: 4,
    ten: 'Sale VVIP',
    text: 'Sale VVIP',
  },
];

export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';
export const PDF_EXTENSION = '.pdf';
export const DOCX_EXTENSION = '.docx';
export const DOC_EXTENSION = '.doc';
export const XLSX_EXTENSION = '.xlsx';
export const XLS_EXTENSION = '.xls';
export const MP4_EXTENSION = '.mp4';
export const PAGE_SIZE_DEFAULT = 10;
export const MAXIMUM_FILE_UPLOAD = 20480000;
export const PAGE_OPTIONS: number[] = [5, 10, 25, 50];

export const TABLE_LOG = {
  VanBanDen: 'DocumentIn',
  VanBanDi: 'DocumentOut',
};

export const ERROR_CODE = {
  ERROR_BOOKNUMBER: 'Error_BookNumber',
  ERROR_DOCUMENTCODE: 'Error_DocumentCode',
  ERROR_LANHDAO: 'ERROR_100',
};

export const DONVI_NGOAINGANH = {
  TRONG_BTC: '1',
  NGOAI_BTC: '2',
};

export const FORM_ACTION = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

export const LIST_DONVI_KIEULOAI: any[] = [
  {
    id: 1,
    ten: 'Chủ đầu tư',
  },
  {
    id: 2,
    ten: 'Đại lý',
  },
];

export const LIST_GIOITINH: any[] = [
  {
    id: 'NAM',
    ten: 'Nam',
  },
  {
    id: 'NU',
    ten: 'Nữ',
  },
  {
    id: 'KHAC',
    ten: 'Khác',
  },
];

export const KIEU_LOAI_NHAN_THONGBAO = {
  TAT_CA: 0,
  DON_VI: 1,
  CA_NHAN: 2,
};

export const KIEU_LOAI_THONGBAO = {
  TIN_TUC: '1',
  THONG_BAO: '2',
  TINTUC_CUDAN: '3', // chủ hộ hoặc role thành viên chủ hộ
  TINTUC_ANNINH: '4', // Scanner
  KIEN_THUC_SALE: '5', // Scanner
};

export const KIEU_LOAI_MXH_FEED = {
  CONG_DONG: '1',
  KIEN_THUC_SALE: '2',
};

export const SORT_DIRECTION = {
  ASCE: 1,
  DESC: 2,
};

export const VerificationCode_Type = {
  NhapGiaBds: 1,
  PhanQuyenBds: 2,
  DoiMatKhau: 3,
  LayLaiMatKhau: 4,
};
export const QUANHE_CHUHO: any[] = [
  {
    id: 1,
    ten: 'Người thân',
  },
  {
    id: 2,
    ten: 'Thành viên',
  },
  {
    id: 3,
    ten: 'Khác',
  },
];
export const role_code = {
  SALE: 'SALE',
  ADMIN_CHUDAUTU: 'ADMIN_CHUDAUTU',
  ADMIN_QUANTRICHUNG: 'ADMIN_QUANTRICHUNG',
  LE_TAN: 'LE_TAN',
  BAN_BE: 'BAN_BE',
  QUANLY_ECP: 'QUANLY_ECP',
  NHANVIEN_ECP: 'NHANVIEN_ECP',
};

export const XEBUS_DIEM_LEN_XUUONG = [
  {
    value: 1,
    text: 'Điểm Lên xuống',
  },
  {
    value: 2,
    text: 'Điểm Lên',
  },
  {
    value: 3,
    text: 'Điểm xuống',
  },
];

export const DEVICE_TYPE = {
  QR_DEVICE_2_DAU_DOC: 1, //Bộ thiết bị nhận dạng mã QR (2 đầu đọc) (Lắp đặt tại công viên)
  QR_DEVICE_1_DAU_DOC: 2, //Bộ thiết bị nhận dạng mã QR (1 đầu đọc) (Lắp đặt trên xe Bus)
  PLATENUMBER_DEVICE_2_DAU_DOC: 3, //Bộ thiết bị nhận dạng biển số xe (2 đầu đọc) (Lắp đặt tại công viên)
};

export const LOAI_CHUC_DANH = {
  SALE: 1,
  ORTHER: 2,
};

export const REGEX = {
  SO_DIEN_THOAI: '^(84|0[3|5|7|8|9])+([0-9]{8}$)',
};

export const LIST_LOAICHECKIN: any[] = [
  {
    id: 1,
    value: 1,
    text: 'Vai trò',
  },
  {
    id: 2,
    value: 2,
    text: 'Thành viên',
  },
  {
    id: 3,
    value: 3,
    text: 'Vé mời',
  },
];

export const ServiceCode = {
  ADMIN_DEVICE: 'ADMIN_DEVICE',
  BUS: 'BUS',
};

export const EventType = {
  RESIDENT_EVENT_RUNNING: 'RESIDENT_EVENT_RUNNING', //Sự kiện chạy
  RESIDENT_EVENT: 'RESIDENT_EVENT', //Sự kiện Resident
  ECOSALE_EVENT: 'ECOSALE_EVENT', //Sự kiện EcoSale
};
