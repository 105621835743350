import { HttpHeaders, HttpRequest } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

export function getUrlImage(idImage: string | null, size: string = 'o'): string | null {
  if (idImage == null || idImage == '')
    return null;
  else {
    console.log("environment.SERVICE_API_FILE", environment.SERVICE_API_FILE)
    return `${environment.SERVICE_API_FILE}Files/GetFile?id=${idImage}&size=${size}`
  }
}

export function calculateDiff(startDate, endDate = undefined) {
  startDate = new Date(startDate);
  if (!endDate) {
    endDate = new Date();
  }
  var diff = endDate.getTime() - startDate.getTime();
  var days = Math.floor(diff / (60 * 60 * 24 * 1000));
  var hours = Math.floor(diff / (60 * 60 * 1000)) - days * 24;
  var minutes = Math.floor(diff / (60 * 1000)) - (days * 24 * 60 + hours * 60);
  var seconds =
    Math.floor(diff / 1000) -
    (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60);
  return { day: days, hour: hours, minute: minutes, second: seconds };
}

export function getOptions(maDuAn) {
  let headers = new HttpHeaders().set('MaDuAn', maDuAn);
  const options = {
    headers: headers,
  };
  return options;
}


export function addMonths(months, date?) {
  if (!date) {
    date = new Date();
  }
  date.setMonth(date.getMonth() + months)
  return date;
}

export function fomatDateRequest(date: Date) {
  return date ? moment(date).format() : null;
}

export function fomatTimeRequest(date: Date) {
  return date ? moment(date).format('HH:mm') : null;
}
