import { async } from '@angular/core/testing';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseData } from 'src/app/models/response';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {

  constructor(public httpClient: HttpClient, private _userService: UserService) {
    super(httpClient,'')
  }

  // lấy thông báo theo phân trang
  getNotificationUser() {
    const url = `${environment.SERVICE_NOTI_API}Notifications/GetsByUserId`;
    return this.get<ResponseData>(url);
  }

  //số lượng thông báo chưa xem
  countUnReadNotification(){
    const url = `${environment.SERVICE_NOTI_API}Notifications/UnRead/1`;
    return this.get<ResponseData>(url);
  }

  readNotification(id){
    const url = `${environment.SERVICE_NOTI_API}Notifications/Read/${id}`;
    return this.post<ResponseData>(url, null);
  }

  readAllNotification(){
    const url = `${environment.SERVICE_NOTI_API}Notifications/ReadAll`;
    return this.get<ResponseData>(url);
  }

  unSubscribeTopic(id){
    const url = `${environment.SERVICE_NOTI_API}Notifications/unsubcribeall?userid=`+id;
    return this.post<ResponseData>(url,null);
  }

  
  async registerToken(token): Promise<object> {

    let  user = await this._userService.getCurrentUser().toPromise();
    const data = {
      token: token,
      userId: user.id,
      appId: "WEBSITE",
      "clientId": "99"
    }
    const url = `${environment.SERVICE_NOTI_API}Notifications/registertoken`;
    return this.post<object>(url, data).toPromise();
  }

  deleteToken(token, userid) {
   
    const data = {
      token: token,
      userId: userid,
      appId: "WEBSITE",
      clientId: 0
    }
    const url = `${environment.SERVICE_NOTI_API}Notifications/removetoken`;
    return this.post<ResponseData>(url, data);
  }
}
