import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../models/response';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FileService extends BaseService {

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.SERVICE_API_FILE}Files`)
  }

  layThongTinFile(listFile): Promise<ResponseData> {
    const url = `${this.table}/GetsByIds`;
    return this.post<ResponseData>(url, listFile).toPromise();
  }

  getFile(id, size = 'm'): Promise<ResponseData> {
    
    const url = `${this.table}/GetFile?id=${id}&size=${size}`;
    return this.get<ResponseData>(url).toPromise();
  }

  getImage(id, size = 'm'): Observable<Blob> {
    const url = `${this.table}/GetFile?id=${id}&size=${size}`;
    return this.getDownload(url);
  }

  uploadImage(file):Observable<ResponseData>{
    let formData = new FormData();
    formData.append('file',file);
    const url = `${this.table}/UploadFile`;
    return this.post<ResponseData>(url,formData);
  }
  getPdfData(link) {
    return this.httpClient.post(link, null, { observe: 'response', responseType: 'blob' });
  }
}
