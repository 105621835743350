import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../base.service';
import { ResponseData } from '../../models/response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PageService extends BaseService{

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.ACCOUNT_API}api/Page`)
  }

  layTatCaTheoTree(type: string): Promise<ResponseData> {
    const url = `${this.table}/LayTatcaTheoTree?type=${type}`;
    return this.get<ResponseData>(url).toPromise();
  }

  layTatcaMenu(): Promise<ResponseData> {
    const url = `${this.table}/LayTatcaMenu`;
    return this.get<ResponseData>(url).toPromise();
  }
  
  GetAllForMobile(body): Promise<ResponseData> {
    const url = `${this.table}/GetAllForMobileAsync`;
    return this.post<ResponseData>(url,body).toPromise();
  }

  GetHomePage(): Observable<ResponseData> {
    const url = `${this.table}/GetHomePageByRole`;
    return this.get<ResponseData>(url);
  }

  CheckUserAccess(model): Observable<ResponseData> {
    const url = `${this.table}/CheckUserAccess`;
    return this.post<ResponseData>(url,model);
  }

}
