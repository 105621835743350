<ng-container *ngIf="nzShowTime">
  <nz-date-picker [nzSize]="isSmall == true ? 'small' : 'default'" [nzShowTime]="{ nzFormat: 'HH:mm' }"
    [nzAllowClear]="nzAllowClear" nzFormat="dd/MM/yyyy HH:mm" [(ngModel)]="inputValue"
    (ngModelChange)="updateModel($event)" (keyup.enter)="onKeyupEnter($event)" (keydown.Tab)="onKeyupEnter($event)"
    [nzPlaceHolder]="placeHolder" [nzDisabled]="nzDisabled" [style]="styleCss">
  </nz-date-picker>
</ng-container>

<ng-container *ngIf="!nzShowTime">
  <nz-date-picker [nzAllowClear]="nzAllowClear" nzFormat="dd/MM/yyyy" [nzSize]="isSmall == true ? 'small' : 'default'"
    [(ngModel)]="inputValue" (ngModelChange)="updateModel($event)" (keyup.enter)="onKeyupEnter($event)"
    (keydown.Tab)="onKeyupEnter($event)" [nzPlaceHolder]="placeHolder" [nzDisabled]="nzDisabled" [style]="styleCss">
  </nz-date-picker>
</ng-container>