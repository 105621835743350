import { Guid } from 'guid-typescript';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../base.service';
import { StorageService } from '../storage.service';
import { ResponseData } from 'src/app/models/response';
import { Observable } from 'rxjs';
import { DuyetSaleModel } from 'src/app/models/duyetsalemodel';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.ACCOUNT_API}api/UserProfile`);
  }
  DownloadTemplateUpLevelSale() {
    const url = `${this.table}/DownloadTemplateUpLevelSale`;
    return this.getDownload(url);
  }
  DownloadDataWrongUpLevelSale(body) {
    const url = `${this.table}/DownloadDataWrongUpLevelSale`;
    return this.postDownload(url, body);
  }
  GetUserProfileByNames(): Promise<ResponseData> {
    const url = `${this.table}/GetUserProfileByNames`;
    return this.get<ResponseData>(url).toPromise();
  }
  GetPhanTrangSalesTheoDonVi(body): Promise<ResponseData> {
    const url = `${this.table}/LayPhanTrangSalesTheoDonVi`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  GetTatCaIdSaleTheoDonVi(): Promise<ResponseData> {
    const url = `${this.table}/LayTatCaIdSaleTheoDonVi`;
    return this.get<ResponseData>(url).toPromise();
  }

  nhanVienInit(data): Promise<ResponseData> {
    const url = `${this.table}/NhanVienInit`;
    return this.post<ResponseData>(url, data).toPromise();
  }

  getThongTinCaNhan(): Promise<ResponseData> {
    const url = `${this.table}/GetThongTinCaNhan`;
    return this.get<ResponseData>(url).toPromise();
  }

  capNhatThongTinCaNhan(model: any): Promise<ResponseData> {
    const url = `${this.table}/CapNhatThongTinCaNhan`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  getDonViByUserName(username): Promise<ResponseData> {
    const url = `${this.table}/GetDonViByUserName?username=${username}`;
    return this.get<ResponseData>(url).toPromise();
  }

  getUserInfo(): Promise<ResponseData> {
    const url = `${this.table}/GetUserInfo`;
    return this.post<ResponseData>(url, {}).toPromise();
  }

  testerInit(id: any): Promise<ResponseData> {
    const url = `${this.table}/TesterInit`;
    return this.post<ResponseData>(url, { id: id }).toPromise();
  }

  createTest(body): Promise<ResponseData> {
    const url = `${this.table}/ThemMoiTest`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  updateTest(body): Promise<ResponseData> {
    const url = `${this.table}/CapNhatThongTinTest`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  deleteTest(id): Promise<ResponseData> {
    const url = `${this.table}/XoaTest/${id}`;
    return this.post<ResponseData>(url, {}).toPromise();
  }

  timTheoDieuKienTest(body) {
    const url = `${this.table}/TimTheoDieuKienTest`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  LayTatCaSaleTheoDonVi(): Promise<ResponseData> {
    const url = `${this.table}/LayTatCaSaleTheoDonVi`;
    return this.get<ResponseData>(url).toPromise();
  }
  timTheoDonVi(body) {
    const url = `${this.table}/TimTheoDonVi`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  doiMatKhau(model): Promise<ResponseData> {
    const url = `${this.table}/DoiMatKhau`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  GetSaleByIdDonVi(idDonVi): Promise<ResponseData> {
    const url = `${this.table}/GetSaleByIdDonVi/${idDonVi}`;
    return this.get<ResponseData>(url).toPromise();
  }

  downloadTemplateImport() {
    const url = `${this.table}/DownloadFileImportSale`;
    return this.getDownload(url);
  }
  GetSaleByIdDonViAndKeyword(idDonVi, keyword) {
    const url = `${this.table}/GetSaleByIdDonViAndKeyword/?idDonVi=${idDonVi}&keyword=${keyword}`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetAllSaleInThe(model): Promise<ResponseData> {
    const url = `${this.table}/GetAllSaleInThe`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  ThemMoiSale(model: any): Promise<ResponseData> {
    const url = `${this.table}/ThemMoiSale`;
    return this.post<ResponseData>(url, model).toPromise();
  }
  CapNhatThongTinSale(model: any): Promise<ResponseData> {
    const url = `${this.table}/CapNhatThongTinSale`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  DoiMatKhauNhanVien(model): Promise<ResponseData> {
    const url = `${this.table}/DoiMatKhauNhanVien`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  FindSaleTheoDonViInThe(model) {
    const url = `${this.table}/GetAllSaleInThe`;
    return this.post<ResponseData>(url, model);
  }

  CapNhatLevelSale(model: any): Promise<ResponseData> {
    const url = `${this.table}/CapNhatLevelSale`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  ScannerCapNhatThongTin(model: any): Promise<ResponseData> {
    const url = `${this.table}/ScannerCapNhatThongTin`;
    return this.post<ResponseData>(url, model).toPromise();
  }
  ScannerThemMoi(model: any): Promise<ResponseData> {
    const url = `${this.table}/ScannerThemMoi`;
    return this.post<ResponseData>(url, model).toPromise();
  }
  ScannerTimTheoDieuKienAsync(model: any): Promise<ResponseData> {
    const url = `${this.table}/ScannerTimTheoDieuKienAsync`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  ScannerNhanVienInit(data: any): Promise<ResponseData> {
    const url = `${this.table}/ScannerNhanVienInit`;
    return this.post<ResponseData>(url, data).toPromise();
  }

  GetInfoByQrsale(qrcode: string): Promise<ResponseData> {
    const url = `${this.table}/GetInfoByQrsale/${qrcode}`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetUserByFillter(hoTen?, userId?, roleCode?): Observable<ResponseData> {
    var body = {
      keyword: hoTen,
      userId: userId,
      roleCode: roleCode,
    };
    let url = `${this.table}/SearchGetUser`;
    return this.post<ResponseData>(url, body);
  }

  getListDuAn(): Promise<ResponseData> {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDuAn/LayTatCa`;
    return this.get<ResponseData>(url).toPromise();
  }

  getListNhaMauByDuAn(idDuAn: Guid): Promise<ResponseData> {
    const url =
      `${environment.SERVICE_API_MAIN}api/NhaMau/GetListNhaMauByDuAn?idDuAn=` +
      idDuAn;
    return this.get<ResponseData>(url).toPromise();
  }

  ganNhaMau(body: any): Promise<ResponseData> {
    const url = `${this.table}/GanNhaMau`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  getConfigUserNhaMau(userId: Guid): Promise<ResponseData> {
    const url =
      `${environment.ACCOUNT_API}api/UserProfile/GetConfigUserNhaMau?userId=` +
      userId;
    return this.get<ResponseData>(url).toPromise();
  }

  pheDuyetSale(model: DuyetSaleModel): Promise<ResponseData> {
    const url = `${environment.ACCOUNT_API}api/UserProfile/NhanVienSaleDuyet`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  FindTacGia(model) {
    const url = `${this.table}/GetTacGia`;
    return this.post<ResponseData>(url, model);
  }
  LayTatCaUserTheoDonVi(body: any): Promise<ResponseData> {
    const url = `${this.table}/LayTatCaUserTheoDonVi`;
    return this.post<ResponseData>(url, body).toPromise();
  }


}
