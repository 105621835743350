<div class="header">
    <div id="logo" class="logo logo-dark">
        <a href="">
            <img src="assets/images/logo/logo.png" class="img-logo" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold.png" alt="Logo">
        </a>
    </div>
    <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/logo-white.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
        </ul>
        <ul class="nav-right">
            <li>
                <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="popNotification" (nzVisibleChange)="changeViewNotification($event)" [nzPlacement]="'bottomRight'">
                    <nz-badge [nzCount]="soThongBao">
                        <i nz-icon nzType="bell" theme="outline"></i>
                    </nz-badge>
                </a>
                <nz-dropdown-menu #popNotification="nzDropdownMenu">
                    <div nz-menu class="pop-notification">
                        <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                            <p class="text-dark font-weight-semibold m-b-0">
                                <i nz-icon nzType="bell" theme="outline"></i>
                                <span class="m-l-10">Thông báo</span>
                            </p>
                            <a href="javascript:void(0)" *ngIf="soThongBao>0" (click)="readAllNotification(true)" class="p-v-5">Đã xem</a> 
                        </div>
                        <div class="content-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">
                            <perfect-scrollbar  [config]="config">
                                <nz-list class="ant-list-item-links" [nzDataSource]="notificationList" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                                    <ng-template #item let-item>
                                        <nz-list-item [style]="item.isView?'':'background-color: aliceblue'" class="notification-item"  >
                                            <a href="javascript:void(0)" (click)="readNotification(item)"  *ngIf="item.url" class="p-b-10">
                                                <div class="d-flex">
                                                    <nz-avatar *ngIf="item.image" [nzSrc]="item.image" ></nz-avatar>
                                                    <div class="m-l-15" style="flex: 1;">
                                                        <p class="m-b-0 text-dark">{{item.content}}</p>
                                                        <p class="m-b-0"><small>{{item.time}}</small></p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0)" *ngIf="!item.url" (click)="readNotification(item)" class="p-b-10">
                                                <div class="d-flex">
                                                    <nz-avatar *ngIf="item.image" [nzSrc]="item.image | image"></nz-avatar>
                                                    <div class="m-l-15" style="flex: 1;">
                                                        <p class="m-b-0 text-dark">{{item.content}}</p>
                                                        <p class="m-b-0"><small>{{item.time}}</small></p>
                                                      </div>
                                                </div>
                                            </a>
                                            
                                        </nz-list-item>
                                    </ng-template>
                                </nz-list>
                            </perfect-scrollbar>
                            </div>
                      
                    </div>
                </nz-dropdown-menu>
            </li>
            <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                    <nz-avatar [nzSrc]="userAvatar"></nz-avatar>
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <nz-avatar nzSize="large" [nzSrc]="userAvatar"></nz-avatar>
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">{{userLogin?.displayName}}</p>
                                    <p class="m-b-0 opacity-07">{{userLogin?.userName}}</p>
                                </div>
                            </div>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="onCapNhatThongTin($event)">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Cập nhật thông tin</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>

                        <li *ngIf="isSale" nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="onDangKyTenMien()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="plus" theme="outline"></i>
                                    <span class="m-l-10">Đăng ký tên miền</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>

                        <li *ngIf="isSale" nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="onQuanLyGiaoDien()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="appstore" theme="outline"></i>
                                    <span class="m-l-10">Quản lý giao diện</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>

                        <li *ngIf="isSale" nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="onCauHinhWebsite()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="global" theme="outline"></i>
                                    <span class="m-l-10">Cấu hình website</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>

                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="onChangePass($event)">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                                    <span class="m-l-10">Đổi mật khẩu</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Logout</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu >
            </li>
            
        </ul>
    </div>
</div>    