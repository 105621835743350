import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { ThemeService } from 'src/app/services/main/theme.service';
@Component({
    selector: 'app-quanly-giaodien',
    templateUrl: './quanly-giaodien.component.html',
    styleUrls: ['./quanly-giaodien.component.scss']
})
export class QuanlyGiaodienComponent implements OnInit {

    lstTheme: Array<any> = [];
    themeDetail: any;
    
    constructor(private _themeService: ThemeService,
        private _notification: NzNotificationService) {

    }

    ngOnInit() {
        this.gridLoadData();
    }

    gridLoadData() {
        this.GetAllTheme();
    }

    GetAllTheme() {
        this._themeService.GetAllTheme().then(rs => {
            if (rs.success) {
                this.lstTheme = rs.data
                console.log(rs.data);
            }
            else {
                this._notification.error(MESSAGE.ERROR, rs.message);
            }
        })
    }

    onSaveTheme(id) {
        console.log(id);
        this._themeService.getDetail(id).then(rs => {
            if (rs.success) {
                this.themeDetail = rs.data

                this._themeService.SaveThemeConfig(rs.data).then(rs => {
                    if (rs.success) {
                        this._notification.success(MESSAGE.SUCCESS, MESSAGE.USE_SUCCESS);
                        this.gridLoadData()
                    } else {
                        this._notification.error(MESSAGE.ERROR, rs.message);
                    }
                })
            }
            else {
                this._notification.error(MESSAGE.ERROR, rs.message);
            }
        })
    }
}
