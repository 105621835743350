import { filter } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FileService } from './../../services/file.service';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {


  @Input() onlyImage: boolean = true
  @Input() isMultiple: boolean = false
  urlApi: string = "";
  fileIds: any;
  @Input() fileType: string
  filterImageType: string = "image/png,image/jpeg,image/gif,image/bmp"
  fileList: NzUploadFile[] = [
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    // },
  ];
  isImageAllowed = false;
  listFileUpload = [];

  previewImage: string | undefined = '';
  previewVisible = false;

  widthModal: number = 900;



  isUploading = false;
  @Output() fileSelected: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private _modalService: NzModalService,
    private _notification: NzNotificationService,
    private _fileService: FileService,
    private http: HttpClient,
  ) {

    this.urlApi = `${environment.SERVICE_API_FILE}Files/UploadFile`
  }

  public onChange: (data: any) => void;
  public onTouched: () => void;

  writeValue(obj: any): void {

    if (this.isMultiple) {

      if (obj) {
        obj = obj.toLowerCase();
        this.fileIds = (obj as string).split(';')
      } else {
        this.fileIds = []
      }

    } else {
      if (obj) {
        obj = obj.toLowerCase();
        this.fileIds = [obj]
      } else {
        this.fileIds = []
      }
    }

    if (this.fileIds.length > 0) {
      this.layThongTinFile();
    }

    // this.fileList = obj;
    // if (this.fileList && this.fileList.length > 0) {

    // } else {
    //   this.listFileUpload = [];
    // }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  layThongTinFile() {
    const body = {
      ids: this.fileIds.join(";"),
    };
    this._fileService.layThongTinFile(body).then(res => {
      if (res.success) {

        this.fileList = res.data.map(b => {
          return {
            uid: b.id,
            name: b.fileName,
            response: {
              data: {
                id: b.id,
                fileName: b.fileName,
              }
            },
            status: 'done',
            url: `${environment.SERVICE_API_FILE}Files/GetFile?id=${b.id}&size=o`

          }
        });
      } else {
        this._notification.error(MESSAGE.ERROR, res.error);
      }
    })
  }




  ngOnInit() {
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  onUpdateFileToModel() {

    if (this.onChange) {
      this.onChange(this.fileIds.join(";"));
    }
  }

  onRemove = (file: NzUploadFile): boolean | Observable<boolean> => {
    console.log('Delete file:', file);
    this.fileIds = this.fileIds.filter(b => b != file.response.data.id)
    this.fileList = this.fileList.filter(b => b.uid != file.response.data.id)
    this.onUpdateFileToModel()
    return true;
  };



  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.isUploading = false;
      this.fileIds.push(info.file.response.data.id);
      info.file.uid = info.file.response.data.id;
      this.onUpdateFileToModel()
      this.fileSelected.emit({ id: info.file.uid, type: ('.' + info.file.name.split('.').pop()).toLowerCase() });
    } else if (info.file.status === 'error') {
      this.isUploading = false;
      console.log(info)
      //this.notification.error(MESSAGE.ERROR, MESSAGE.SYSTEM_ERROR);
    }
    // }
  }

  onShowUpload() {
    if (this.isMultiple) {
      return this.fileIds.length < 8
    } else {
      return this.fileIds.length == 0 && !this.isUploading
    }
  }

  private checkImageType(file) {
    const imgType = this.filterImageType.split(","); // list image
    this.isImageAllowed = imgType.includes(file.type)

  }

  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean | Observable<boolean> => {
    //Kiểm tra là upload ảnh
    if (this.onlyImage) {
      if (file.size > (1024 * 1024 * 10)) {
        this._notification.error('Lỗi Upload', "File có dung lượng lớn hơn 10 MB");
        return false;
      }
      this.checkImageType(file);
      if (!this.isImageAllowed) {
        this._notification.error('Lỗi Upload', "File không đúng định dạng ảnh");
        return false;
      }
      this.isUploading = true;

      // returning of(false) will not prevent from process upload.
      return this.isImageAllowed;
    } else if (this.fileType) {
      const imgType = this.fileType.split(","); // list image
      this.isImageAllowed = imgType.includes(file.type)
      if (!this.isImageAllowed) {
        this._notification.error('Lỗi Upload', "File không đúng định dạng");
        return false;
      }
      this.isUploading = true;

      // returning of(false) will not prevent from process upload.
      return this.isImageAllowed;
    }


    this.isUploading = true;
    return true;
  }

  onDownLoad = (file: NzUploadFile) => {
    this._fileService.getImage(file.response.data.id, "o").subscribe(res => {
      if (res) {
        saveAs(res, file.filename);
      }
    })
  }

}
