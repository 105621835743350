import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import vi from '@angular/common/locales/vi';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { CommonInterceptor } from './interceptor/common.interceptor';
import { NZ_I18N, vi_VN } from 'ng-zorro-antd/i18n';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { SharedModule } from './shared/shared.module';
import { TemplateModule } from './shared/template/template.module';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { Observable, tap } from 'rxjs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { GALLERY_CONFIG } from 'ng-gallery';
import { LIGHTBOX_CONFIG } from 'ng-gallery/lightbox';
import { JwtModule } from '@auth0/angular-jwt';
import { CommonV2Interceptor } from './interceptor/commonv2.interceptor';
import { CookieModule } from 'ngx-cookie';

registerLocaleData(vi);

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

const ngZorroConfig: NzConfig = {
  notification: { nzMaxStack: 1 },
  modal: { nzMaskClosable: false },
  // table: {nzSize: 'small'},
  // pagination : {nzSize : 'small'},
  // button: {nzSize: 'small'}
};

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    NzBreadCrumbModule,
    TemplateModule,
    NgxSpinnerModule.forRoot({ type: 'ball-beat' }),
    provideFirebaseApp(() => initializeApp(environment.fireBaseConfig)),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.fireBaseConfig),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    CookieModule.forRoot(), 
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: CommonV2Interceptor,
    },
    {
      provide: NZ_I18N,
      useValue: vi_VN
    },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    ThemeConstantService,
    {
      provide: GALLERY_CONFIG,
      useValue: {
      }
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true,
        hasBackdrop:true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
