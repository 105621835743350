import { DsBdsComponent } from '../../../pages/bds/phanquyendotban-dl/ds-bds/ds-bds.component';
import { UserProfileService } from 'src/app/services/core/userProfile.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogin } from 'src/app/models/userlogin';
import { UserService } from 'src/app/services/core/user.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseData } from 'src/app/models/response';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { AuthService } from 'src/app/services/auth.service';
import { InputValidators } from '../../Validators/inputValidators';



@Component({
  selector: 'app-form-user-changepass',
  templateUrl: './form-user-changepass.component.html',
  styleUrls: ['./form-user-changepass.component.css']
})
export class FormUserChangePassComponent implements OnInit {

  isLoading = false;
  id: any;

  formInput: FormGroup;

  passwordVisible = false;
  listOfRole = [];

  modelUser: any = {};
  modelDonVi: any = {};
  modelUserName: string;

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private _userService: UserProfileService,
    private spinner: NgxSpinnerService,
    private _notification: NzNotificationService,

  ) { }

  ngOnInit() {
    this.formInput = this.fb.group({
      oldPassword: [null, [Validators.minLength(6), Validators.required]],
      newPassword: [null, [Validators.required,
        , InputValidators.checkPass("Password không hợp lệ!"),]],
      confirmPassword: [null, [Validators.required,
        , InputValidators.checkPass("Password không hợp lệ!"),]],
    });
  }

  onViewPass() {
    this.passwordVisible = !this.passwordVisible;
  }


  markFormGroupTouched(formGroup) {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  onSave() {

    this.markFormGroupTouched(this.formInput);
    if (this.formInput.invalid) {
      return;
    }
    let model = this.formInput.value
    this.spinner.show();
    this._userService.doiMatKhau(model).then((res: ResponseData) => {
      this.spinner.hide();
      if (res.success) {
        this._notification.success(MESSAGE.SUCCESS, "Cập nhật thành công");
        this.modal.close(true);

      } else {
        this._notification.warning(MESSAGE.WARNING, res.message);
      }
    })

  }

  onThoat() {
    this.modal.close(false);
  }

}
