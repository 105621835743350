import { Injectable } from '@angular/core';
import { getMessaging, getToken } from "@angular/fire/messaging";
import { FirebaseApp } from '@angular/fire/app';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { ResponseData } from 'src/app/models/response';
@Injectable({
  providedIn: 'root'
})

export class FirebaseService {
  messaging: any;
  constructor(
    private firebaseApp: FirebaseApp,
    private angularFireMessaging: AngularFireMessaging,
    public httpClient: HttpClient,
    private _userService: UserService
  ) {

    this.messaging = getMessaging(firebaseApp);
  }


  getTokenApp(): Promise<string> {
    return getToken(this.messaging, { vapidKey: environment.vapidKey})
  }

  requestPermission() {
    return this.angularFireMessaging.requestPermission;
  }

  receiveMessage() {
    return this.angularFireMessaging.messages;
  }
  tokenChange() {
    return this.angularFireMessaging.tokenChanges;
  }
}
