import { UserLogin } from './../models/userlogin';
import { PageService } from 'src/app/services/core/page.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { catchError, concat, map, Observable, of } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/core/user.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private _pageService: PageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let url: string = state.url;
      return new Observable<boolean>(obs => {
        this.userService.getCurrentUser().subscribe((user : UserLogin) =>{
          if(user){

            if(url){
              url = url.split("?")[0]
            }
            if(url == "/"){
              obs.next(true);
            }

            this._pageService.CheckUserAccess({url: url }).subscribe(rs =>{
              if(rs.success){
                obs.next(true);
                return;
              }else{
                this.authService.redirectUrl = url;
                obs.next(false);
                this.router.navigate(['/']);
                return;
              }
            },(err) =>{
              obs.next(false);
              this.router.navigate(['/']);
              return;
            })

          }else{
            this.userService.ClearCurrentUser();
            this.authService.redirectUrl = url;
            obs.next(false);
            this.router.navigate(['login'],{ queryParams: { goto: url } });

          }

        });
      });


      //****************** */
      // const userLogin  = this.userService.getUserLogin();
      // //let url: string = state.url;
      // if (userLogin === null ) {
      //   this.authService.redirectUrl = url;
      //   this.router.navigate(['login'],{ queryParams: { goto: url } });
      //   return false;
      // } else {
       
      //   if(url){
      //     url = url.split("?")[0]
      //   }
      //   if(url == "/"){
      //     return true;
      //   }
      // return  this._pageService.CheckUserAccess({url: url }).pipe(
      //   map(rs => {
      //     if(!rs.success){
      //       this.authService.redirectUrl = url;
      //       this.router.navigate(['/']);
      //     }
      //     return rs.success;
      //   }),
      //   catchError((err) => {
      //     this.router.navigate(['/']);
      //     return of(false);
      //   })
        
      // )

      //****************** */
        // const user  = this.userService.getUserLogin();
       
        // if(user && user.id && (user.isSuperUser || user.pages.includes(url) || url == "/") ){
        //   return true;
        // }else{
        //   this.authService.redirectUrl = url;
        //   this.router.navigate(['/']);
        //   return false;
        // }
        
      //}
  }

}
