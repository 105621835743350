import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  EMPTY,
  Observable,
  switchMap,
  catchError,
  BehaviorSubject,
  from,
  throwError,
} from 'rxjs';
import { AuthService } from '../services/auth.service';
import { filter, finalize, mergeMap, take, tap } from 'rxjs/operators';
import { ERROR_CODE, STATUS_CODE } from '../constants/config';
import { MESSAGE } from '../constants/message';
import { ResponseData } from '../models/response';
import { NgxSpinnerService } from 'ngx-spinner';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CryptHelperService } from '../services/cryptohelper.service';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommonV2Interceptor implements HttpInterceptor {
  refreshing = false;
  token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private notification: NzNotificationService,
    private _cookieService: CookieService,
    private router: Router
  ) { }

  addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    let headers = request.headers;
    headers = headers.append('Ma-App', environment.MA_APP);
    if (token) {
      if (
        !(
          request.url.includes('UploadFile') ||
          request.url.includes('Import') ||
          request.url.includes('GetFile')
        )
      ) {
        headers = headers.append('content-type', 'application/json');
      }
      return request.clone({
        headers,
        withCredentials: true,
      });
    } else {
      return request.clone({
        headers,
        withCredentials: true,
      });
    }
  }

  handle401(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // error.error.msg === "Token has expired";

    return this.authService.refreshTokenEnc({}).pipe(
      mergeMap((data: ResponseData) => {
        if (data && data.success) {
          //console.log('goi refreshtoken thành công');

          // var result = JSON.parse(this._cryptHelperService.Decrypt_Data(data.data))
          // this.authService.saveToken(result.token);
          // this.token$.next('result.token');
          // Fixme:
          // let req = next.handle(this.addToken(request, 'result.token')).pipe( tap((res : any) => {
          //   console.log('data2222: ', req);
          // }));
          this.setCookie("exptk", data.data.exp)
          return next.handle(this.addToken(request, 'result.token'));
        } else {
          this.spinner.hide();
          //console.log('goi refreshtoken hết hạn');
        }
        return from(this.authService.logoutV2());
      }),
      catchError((error) => {
        console.log(`[AuthInterceptor.performTokenRefresh] error "${error}"`);
        this.spinner.hide();
        if (error.url.includes('tokenrefresh')) {
          return from(this.authService.logoutV2());
        }

        return error;
      }),
      finalize(() => {
        this.refreshing = false;
      })
    );

  }

  async handle400(error): Promise<any> {
    this.spinner.hide();
    console.error(`[400 handler] "${error.message}"`);
    return from(this.router.navigateByUrl('/'));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('login') ||
      request.url.includes('tokenrefresh') ||
      request.url.includes('logout') ||
      request.url.includes('check-need-otp')
    ) {
      return next.handle(request);
    }

    if (this.RouteWithOutToken(request.url)) {
      return next.handle(request);
    }

    // var isTokenExpired = this.isTokenExpired();
    // if (isTokenExpired) {
    //   return this.handle401(request, next);
    // }

    return next.handle(this.addToken(request, 'accessToken')).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!(error.error instanceof ErrorEvent)) {
          // api call failure response
          switch (error.status) {
            case 400:
            case 422:
              return this.handle400(error);
            case 401:
              return this.handle401(request, next);
            case 403:
              this.spinner.hide();
              this.notification.error(
                'Forbidden',
                'Bạn không có quyền gọi api này: ' + error.url
              );
              console.log('error: ', error.message);
              break;
            default:
              this.spinner.hide();
              if (error.status !== 0) {
                console.log('error: ', error);
              }
          }
        }
        return throwError(error);
      })
    );
  }

  ReturnReponse(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const result = event.body as ResponseData;
            if (
              result.success === false &&
              !(Object.values(ERROR_CODE).indexOf(result.error) > -1)
            ) {
              // this.notification.error(MESSAGE.ERROR, result.error);
              console.log(`${MESSAGE.ERROR}: ${result.error}`);
              this.spinner.hide();
            }
          }
        },
        (err: any) => {
          if (err.status === STATUS_CODE.UNAUTHORIZED) {
            this.authService.logout(true);
          }
          this.spinner.hide();
          // this.notification.error(MESSAGE.ERROR, MESSAGE.SYSTEM_ERROR);
          console.log(`${MESSAGE.ERROR}: ${MESSAGE.SYSTEM_ERROR}`);
        }
      )
    );
  }

  RouteWithOutToken(url: string) {
    if (
      url.indexOf('login') > -1 ||
      url.indexOf('tokenrefresh') > -1 ||
      url.indexOf('logout') > -1 ||
      url.indexOf('check-need-otp') > -1
    ) {
      return true;
    }
    return false;
  }

  getCookie(key: string) {
    let keyCookie = environment.COOKIE_PREFIX + key;
    return this._cookieService.get(keyCookie);
  }
  setCookie(key: string, value: any) {
    let keyCookie = environment.COOKIE_PREFIX + key;
    return this._cookieService.put(keyCookie, value);
  }

  getTimeStamp(mydate: Date) {
    return Math.floor(mydate.getTime() / 1000);
  }

  isTokenExpired() {
    var exp = this.getCookie('exptk');
    if (exp) {
      var timeNow = this.getTimeStamp(new Date());
      if (parseInt(exp) < timeNow) return true;

      return false;
    }
    return true;
  }
}
