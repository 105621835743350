<div class="common-layout {{ 'is-' + selectedHeaderColor }}" 
    [ngClass]="{
        'is-folded': isFolded,
        'is-side-nav-dark' : isSideNavDark,
        'is-expand' : isExpand
    }"
>
    <app-header></app-header>
    <app-sidenav></app-sidenav>
    <div class="page-container">
        <div class="main-content">
            <!-- <div class="main-content-header" *ngIf="contentHeaderDisplay !== 'none'">
                <h4 class="page-title" *ngIf="breadcrumbs$ | async; let breadcrumbs">{{(breadcrumbs[breadcrumbs.length - 1].label)}}</h4>
               
            </div> -->
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
    
</div>
<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
    <p style="font-size: 20px; color: white">Đang tải...</p>
</ngx-spinner>