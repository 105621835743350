import { DomainService } from './../../../services/main/domain.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { LinkAffiliateService } from 'src/app/services/main/linkAffiliate.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-dangky-tenmien',
    templateUrl: './dangky-tenmien.component.html',
    styleUrls: ['./dangky-tenmien.component.scss']
})
export class DangkyTenmienComponent implements OnInit {
    formInput: FormGroup;
    lstDataBds: any;
    disableButton = false;
    tenMienHienTai = ""

    lstDataDomain: Array<any> = []

    lstTenMien: Array<any> = []

    constructor(
        private _domainService: DomainService,
        private _notification: NzNotificationService,
        private fb: FormBuilder,
        private linkAff: LinkAffiliateService,
        private modal: NzModalRef,
    ) {

    }

    ngOnInit() {
        this.gridLoadData();
        this.initForm();
        this.GetTenMienHienTai();
    }

    initForm() {
        this.formInput = this.fb.group({
            ma: [null, [Validators.required]],
        })
    }

    onCheckboxChange(data){
        data.select = !data.select;
    }

    gridLoadData() {
        this._domainService.layTatca().then(rs => {
            if (rs.success) {
                this.lstDataDomain = rs.data.map(item => {
                    return { ...item, select: true };
                  });
            }
            
        })
    }

    GetTenMienHienTai(){
        this._domainService.GetTenMienHienTai().then(rs => {
            if(rs.success){
                this.tenMienHienTai = rs.data   
            }
            else {
                this._notification.error(MESSAGE.ERROR, rs.message);
            }
        })
    }

    onKiemTraTenMien() {
        let key = this.formInput.controls['ma'].value;
        let exts = '';

        if (key.toLocaleLowerCase() == "") {
            this._notification.error(MESSAGE.ERROR, "Chưa nhập tên miền kiểm tra");
            return;
        }

        if (/\W/.test(key) || [...key].some(x => x.charCodeAt(0) > 255) || key.toLowerCase().includes("đ")) {
            this._notification.error(MESSAGE.ERROR, "Tên miền phải viết liền không dấu và không chứa chứa ký tự đặc biệt");
        }

        console.log(this.lstDataDomain);
        
        this.lstDataDomain.forEach(element => {
            if(element.select){
                exts += ";" + element.ten
            }
        });

        exts = exts.replace(/^;/, '').trim();

        this.linkAff.CheckLinkAffiliate(key, exts).then(rs => {
            if (rs.success) {
                this.lstTenMien = rs.data
            }
            else {
                this._notification.error(MESSAGE.ERROR, rs.message);
            }
        })
    }
    rowSelected = "";
    onChonTenMien(value) {
        this.rowSelected = value;
        if (value.trim() == "") {
            this._notification.error(MESSAGE.ERROR, "Link chọn không hợp lệ");
            return;
        }

        this.linkAff.RegisterLinkAffiliate(value).then(rs => {
            if (rs.success) {
                this.onKiemTraTenMien()
            }
            else {
                this._notification.error(MESSAGE.ERROR, rs.message);
            }
        })
    }

    onThoat() {
        this.modal.close(false);
    }
}
