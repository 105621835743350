import { MESSAGE } from 'src/app/constants/message';
import { ReadPropExpr } from '@angular/compiler';
import { AfterViewInit, ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserLogin } from 'src/app/models/userlogin';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/core/firebaseService';
import { NotificationService } from 'src/app/services/core/notification.service';
import { UserService } from 'src/app/services/core/user.service';
import { environment } from 'src/environments/environment';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { FormUserChangePassComponent } from '../form-user-changepass/form-user-changepass.component';
import { FormUserInfoComponent } from '../form-user-info/form-user-info.component';
import * as moment from 'moment';
import { calculateDiff } from '../../helper/ultility';
import { DangkyTenmienComponent } from 'src/app/pages/quantri/dangky-tenmien/dangky-tenmien.component';
import { QuanlyGiaodienComponent } from 'src/app/pages/quantri/quanly-giaodien/quanly-giaodien.component';
import { LinkAffiliateService } from 'src/app/services/main/linkAffiliate.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit,AfterViewInit {

    public config: PerfectScrollbarConfigInterface = {};
    
    searchVisible: boolean = false;
    quickViewVisible: boolean = false;
    isFolded: boolean;
    isExpand: boolean;
    userLogin: UserLogin;

    userAvatar: string = ""
    isNotification = false;
    notificationList: any[];
    soThongBao = 0;
    xemTatCa = true;
    isSale: boolean = false ;
    constructor(
        private themeService: ThemeConstantService,
        private authService: AuthService,
        private modal: NzModalService,
        private _userService: UserService,
        private _fireBaseService: FirebaseService,
        private cdRef: ChangeDetectorRef,
        private appRef: ApplicationRef,
        private notificationService: NotificationService,
        private linkAffSeervice: LinkAffiliateService,
        private notification: NzNotificationService,
    ) { }

    ngOnInit(): void {
        // debugger
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);


        this._userService.getCurrentUser().subscribe(res =>{
            // console.log("UserLogin")
            // console.log(res)
            if(res){
                this.userLogin = res;
                if (res.isChuDauTu) {
                    this.isSale = false;
                  } else if (res.isDaiLy) {
                      this.isSale = false;
                  } else if (res.isSale) {
                    this.isSale = true;
                  }
                if (this.userLogin && this.userLogin.avatarUrl) {
                    this.userAvatar = `${environment.SERVICE_API_FILE}Files/GetFile?id=${this.userLogin.avatarUrl}&size=m`
                } else {
                    this.userAvatar = `assets/images/avatars/thumb-3.jpg`
                }

                this.dangkyfirebase();

            }
           
        })
        this.getNotification();
    }

    ngAfterViewInit() {
        this.getNotification();
    }

    dangkyfirebase() {

        this._fireBaseService.requestPermission().subscribe((token) => {
            if (token === 'granted') {
              this._fireBaseService.getTokenApp().then((value) => {
              });
            }
          }, (error) => {
            console.log("Unable to get perrmison to notify..", error)
        });
      
        this._fireBaseService.tokenChange().subscribe(b => {
            localStorage.setItem('firebaseToken', b ?? "");
            this.notificationService
              .registerToken(b ?? "")
              .then((result) => { });
        });
      
        this._fireBaseService.receiveMessage().subscribe(value => {
            // console.log("get noti firebase")
            // console.log(value)
            if (value) {
              this.notificationList = [...this.notificationList]
              const data = this.getNotificationData(value.data.notificationInfo);
              this.notificationList.unshift({
                title: value.notification.title,
                url: data.message['link'],
                image: data.message['image'] ?? "/assets/icons/icon.png",
                color: 'ant-avatar-' + 'blue',
                content: data.message['content'],
                time: this.getTime(data['ngayTao']),
                isView: false,
                id: data.Id
              })
              this.soThongBao++;
              this.appRef.tick()
            }
          });
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
    }

    onCapNhatThongTin(event) {
        let _modal = this.modal.create({
            nzTitle: 'Cập nhật thông tin cá nhân',
            nzContent: FormUserInfoComponent,
            nzClosable: true,
            nzFooter: null,
            nzStyle: { top: '50px' },
            nzWidth: 1200,
        });
    }

    onDangKyTenMien(){
        let modal = this.modal.create({
            nzTitle: 'Đăng ký tên miền',
            nzContent: DangkyTenmienComponent,
            nzClosable: true,
            nzFooter: null,
            nzStyle: { top: '50px' },
            nzWidth: 1000,
            //nzComponentParams: { id : 0 },
            // nzComponentParams: { data },
          });
    }

    onQuanLyGiaoDien(){
        let modal = this.modal.create({
            nzTitle: 'Quản lý giao diện web',
            nzContent: QuanlyGiaodienComponent,
            nzClosable: true,
            nzFooter: null,
            nzStyle: { top: '50px' },
            nzWidth: 1000,
            //nzComponentParams: { id : 0 },
            // nzComponentParams: { data },
          });
    }

    onCauHinhWebsite(){
        this.linkAffSeervice.GetMyLinkAffiliate().then(rs =>{
            if(rs.success){
                let token = this.authService.getToken()
                let domain = rs.data[0].tenMien;
                const url =  `${domain}`;
                window.open("" + url + "", '_blank');
            }else{
                this.notification.error(MESSAGE.ERROR, "Bạn không có link bán hàng")
                return;
            }
          })
    }

    onChangePass(event) {
        let _modal = this.modal.create({
            nzTitle: 'Thay đổi thông tin mật khẩu',
            nzContent: FormUserChangePassComponent,
            nzClosable: true,
            nzFooter: null,
            nzStyle: { top: '50px' },
            nzWidth: 600,
        });
        _modal.afterClose.subscribe(res => {
            if (res) {
                this.authService.logout();
            }
        });

    }

    logout() {
        this.modal.confirm({
            nzClosable: false,
            nzTitle: 'Xác nhận',
            nzContent: 'Bạn có chắc chắn muốn đăng xuất?',
            nzOkText: 'Đồng ý',
            nzOkDanger: true,
            nzWidth: '350px',
            nzOnOk: () => {
                this._userService.ClearCurrentUser();
                this.authService.logout();
            }
        });
    }

    getNotification() {
        this.notificationService.getNotificationUser().subscribe(value => {
            if (value.success) {
              const data = value.data?.map(e => {
                return {
                  title: e.message.title,
                  url: e.message['link'],
                  image: e.message['image'] ?? "/assets/icons/icon.png",
                  color: 'ant-avatar-' + 'blue',
                  content: e.message['content'],
                  time: this.getTime(e['ngayTao']),
                  isView: e.read?.findIndex(e => { return e.userId == this.userLogin.id }) > -1,
                  id: e.Id
                }
              })
              this.notificationList = [...data]
      
            } else {
              this.notificationList = []
            }
      
            if (this.notificationList.findIndex(e => { return e.isView == false }) > -1) {
              this.xemTatCa = true
            } else {
              this.xemTatCa = false
            }
            this.cdRef.markForCheck()
            this.appRef.tick();
          });

        this.notificationService.countUnReadNotification().subscribe(value => {
            this.soThongBao = value.data
        })
    }

    getNotificationData(notificationInfo) {
        return JSON.parse(notificationInfo);
    }

    getTime(date) {
        var duration = calculateDiff(date);
        if (duration.day > 0) {
            return duration.day + ' ngày trước';
        }
        if (duration.hour > 0) {
            return duration.hour + ' giờ trước';
        }
        if (duration.minute > 0) {
            return duration.minute + ' phút trước';
        }
        return 'vừa xong'
    }

    readNotification(item) {
        if (!item.isView) {
            this.notificationService.readNotification(item.id).subscribe(data => {
                if (data.success) {
                    item.isView = true;
                    this.soThongBao--;
                    this.cdRef.markForCheck()
                    this.appRef.tick()
                    if (item.url) {
                        window.open(item.url, "_blank");
                    }
                }
            });
        } else {
            if (item.url) {
                window.open(item.url, "_blank");
            }
        }
    }

    readAllNotification(value) {
        // gọi APi để read tất cả các thông báo
        if (value==true) {
            this.notificationService.readAllNotification().subscribe(data => {
                if (data.success) {
                    this.soThongBao = 0;
                    this.notificationList.forEach(e => {
                        e.isView = true;
                    })
                    this.notificationList = [...this.notificationList]                   
                    this.cdRef.markForCheck()
                    this.appRef.tick()
                }
            });
        }
    }

    changeViewNotification(event){
        if(event==true){
            this.getNotification()
        }
    }
}
