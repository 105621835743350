import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import * as moment from "moment";
import { NzSafeAny } from "ng-zorro-antd/core/types";

export type MyErrorsOptions = { vi: string } & Record<
  string,
  NzSafeAny
>;
export type MyValidationErrors = Record<string, MyErrorsOptions>;
export class InputValidators extends Validators {
  static minLengthCustom(minLength: number, text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (Validators.minLength(minLength)(control) === null) {
        return null;
      }
      return {
        minlength: {
          vi: text,
        }
      };
    };
  }
  static maxLengthCustom(maxLength: number, text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (Validators.maxLength(maxLength)(control) === null) {
        return null;
      }
      return {
        maxlength: {
          vi: text,
        }
      };
    };
  }
  static mobile(text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (isEmptyInputValue(value)) {
        return null;
      }

      return isMobile(value)
        ? null
        : {
          mobile: {
            vi: text,
          },
        };

    }
  }
  static number(text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (isEmptyInputValue(value)) {
        return null;
      }

      return isNumber(value)
        ? null
        : {
          number: {
            vi: text,
          },
        };

    }
  }
  static emailCustom(text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (isEmptyInputValue(value)) {
        return null;
      }

      return isEmail(value)
        ? null
        : {
          number: {
            vi: text,
          },
        };

    }
  }
  static ngaySinh(text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!moment(value).isSameOrBefore(moment(), 'day') && value != null && value != "") {
        return {
          number: {
            vi: text,
          },
        };
      }
      return null;
    }
  }

  static checkNgay(tuNgay: string, denNgay: string, text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value < Date.parse(tuNgay) || value > Date.parse(denNgay)) {
        return {
          number: {
            vi: text,
          },
        };
      }
      return null;
    }
  }


  static checkGio(tuGio: string, denGio: string, text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      var tuGio1 = Date.parse((moment().format("YYYY-MM-DD") + " " + tuGio));
      var denGio1 = Date.parse((moment().format("YYYY-MM-DD") + " " + denGio));
      if (value < tuGio1 || value > denGio1) {
        return {
          number: {
            vi: text,
          },
        };
      }
      return null;
    }
  }
  static checkPass(text: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {

      const password = control.value as string;
      if (isEmptyInputValue(password)) {
        return null;
      }
      const isLengthValid = password.length >= 8;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasDigit = /[0-9]/.test(password);
      const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);
      if (
        isLengthValid &&
        hasUpperCase &&
        hasLowerCase &&
        hasDigit &&
        hasSpecialCharacter
      ) {
        return null; // Password meets all the criteria
      }
      return {
        password: {
          vi: text,
        },
      };
    }
  }
}
function isEmptyInputValue(value: NzSafeAny): boolean {
  return value == null || value.length === 0;
}

function isMobile(value: string): boolean {
  return typeof value === 'string' && /(^0\d*$)/.test(value);
}
function isNumber(value: string): boolean {
  return typeof value === 'string' && /^[0-9]+$/.test(value);
}
function isEmail(value: string): boolean {
  return typeof value === 'string' && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
}
