import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { ResponseData } from 'src/app/models/response';

@Injectable({
    providedIn: 'root'
  })

export class DomainService extends BaseService{
  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.SERVICE_API_MAIN}api/Domain`)
  }

  UpdateLinkAffiliate(id){
    const url = `${environment.SERVICE_API_MAIN}api/Domain/UpdateLinkAffiliate?id=${id}`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetTenMienHienTai(){
    const url = `${environment.SERVICE_API_MAIN}api/Domain/GetTenMienHienTai`;
    return this.get<ResponseData>(url).toPromise();
  }
}
