<div class="row m-b-30">
    <div class="col-lg-12">
        <div class="row no-gutters" style="margin-bottom: 10px;">
            <div *ngFor="let item of lstTheme" class="col-3 ml-3 cart-content" style="max-width: 23%;">
                <img nz-image width="100%" height="300px" [src]="item.image | image: 'o'" alt="" />
                <div class="cart-bottom">
                    <button class="{{item.activeConfig ? 'btn-success' : 'btn-secondary' }}" disabled="{{ item.activeConfig }}" nzSize="small" (click)="onSaveTheme(item.id)"
                        nz-button>
                        {{ item.activeConfig ? 'Đã áp dụng' : 'Áp dụng' }}
                    </button>
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>