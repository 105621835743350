import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { ResponseData } from 'src/app/models/response';

@Injectable({
    providedIn: 'root'
  })

export class ThemeService extends BaseService{
  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.SERVICE_API_MAIN}api/Theme`)
  }

  SaveThemeConfig(theme){
    const url = `${environment.SERVICE_API_MAIN}api/Theme/SaveThemeConfig`;
    return this.post<ResponseData>(url, theme).toPromise();
  }

  GetAllTheme(){
    const url = `${environment.SERVICE_API_MAIN}api/Theme/GetAllTheme`;
    return this.get<ResponseData>(url).toPromise();
  }
}
