import { DsBdsComponent } from './../../../pages/bds/phanquyendotban-dl/ds-bds/ds-bds.component';
import { UserProfileService } from 'src/app/services/core/userProfile.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogin } from 'src/app/models/userlogin';
import { UserService } from 'src/app/services/core/user.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseData } from 'src/app/models/response';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { LIST_GIOITINH, REGEX } from 'src/app/constants/config';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'app-form-user-info',
  templateUrl: './form-user-info.component.html',
  styleUrls: ['./form-user-info.component.css']
})
export class FormUserInfoComponent implements OnInit {

  isLoading = false;
  id: any;
  
  formInput: FormGroup;

  passwordVisible = false;
  listOfRole =[];

  modelUser: any = {};
  modelDonVi: any = {};
  modelUserName:  string;
  listGioiTinh = LIST_GIOITINH;
  userLogin: UserLogin;

  isHaveQrCode = false;
  constructor( 
    private fb: FormBuilder, 
    private modal: NzModalRef,
    private _userService: UserService, 
    private _userProfileService: UserProfileService ,
    private spinner: NgxSpinnerService,
    private _notification: NzNotificationService,
    private authService: AuthService,
    ) { }

  ngOnInit() {
    this._userService.getCurrentUser().subscribe(u =>{

      if(u){
        this.userLogin = u;
      }
    });
    this.getUserInfo();
  
  }

  getUserInfo(){
    this._userProfileService.getThongTinCaNhan().then(rs =>{
      if(rs.success){
        this.modelUser = rs.data;
        //link qrcode

        if(this.modelUser.qrCodeUrl){
          this.isHaveQrCode = true;
          this.modelUser.linkQrCodeUrl = `${environment.SERVICE_API_FILE}Files/GetFile?id=${this.modelUser.qrCodeUrl}&size=o`
        }
        
        if( this.modelUser.ngaySinh){
          this.modelUser.ngaySinh = new Date( this.modelUser.ngaySinh);
        }
        if(this.modelUser.isPheDuyet){
          this.formInput = this.fb.group({
            tenDonVi : [this.modelUser.maDonVi],
            userName: [this.modelUser.userName],
            ma: [this.modelUser.ma],
            ten: [this.modelUser.ten, [Validators.required]],
            avatarUrl: [this.modelUser.avatarUrl],
            email: [this.modelUser.email,[Validators.email]],
            soDienThoai: [this.modelUser.soDienThoai, [Validators.pattern(REGEX.SO_DIEN_THOAI), Validators.required]],
            diaChi: [null],
            gioiTinh: [this.modelUser.gioiTinh,[Validators.required]],
            ngaySinh: [ this.modelUser.ngaySinh,[Validators.required]],
            chucDanh: [ this.modelUser.chucDanh],

          });
        }else{
          this.formInput = this.fb.group({
            tenDonVi : [this.modelUser.maDonVi],
            userName: [this.modelUser.userName],
            ma: [this.modelUser.ma],
            ten: [this.modelUser.ten, [Validators.required]],
            avatarUrl: [this.modelUser.avatarUrl],
            email: [this.modelUser.email,[Validators.email]],
            soDienThoai: [ {disabled: true, value: this.modelUser.soDienThoai }, [Validators.pattern(REGEX.SO_DIEN_THOAI), Validators.required]],
            diaChi: [null],
            gioiTinh: [this.modelUser.gioiTinh,[Validators.required]],
            ngaySinh: [this.modelUser.ngaySinh,[Validators.required]],
            chucDanh: [ this.modelUser.chucDanh],
          });
        }
       
        this.isLoading = true;
        this.listOfRole = this.modelUser.quyen
      }
    })
  }

  searchUser(event){
    this.spinner.show();
    this._userProfileService.getDonViByUserName(this.modelUserName).then(rs =>{
      this.spinner.hide();
      if(rs.success){
        this.modelDonVi = rs.data
      }else{
        this.modelDonVi = null;
      }
    })
  }

  markFormGroupTouched(formGroup) {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  onSave(){
   
    this.markFormGroupTouched(this.formInput);
    if (this.formInput.invalid) {
      return;
    }

    let model = {...this.formInput.getRawValue(),}
    if(this.modelDonVi && this.modelDonVi.id){
      model = {...model,kieuLoai:this.modelDonVi.kieuLoai,idDonVi: this.modelDonVi.id }
    }

    this.spinner.show();
    this._userProfileService.capNhatThongTinCaNhan(model).then((res: ResponseData) => {
      this.spinner.hide();
      if (res.success) {
        if(this.IsCoQuyen()){
          //Get lại userinfo
          this._userProfileService.getUserInfo().then(rs =>{
            if(rs.success){
              this.authService.saveUser(rs.data);
              this._notification.success(MESSAGE.SUCCESS, "Cập nhật thành công");
            }
          })
        }else{
          this._notification.success(MESSAGE.SUCCESS, "Cập nhật thành công");
        }
      }else{
        this._notification.warning(MESSAGE.WARNING, res.message);
      }
    })
    
  }


  IsCoQuyen(){
    if(this.userLogin && this.userLogin.isSuperUser == true || this.userLogin.isTest == true) return true;

    return false;
  }
  onThoat(){
    this.modal.close(false);
  }

}
