import { NgSelectModule } from '@ng-select/ng-select';
import { VndPipe } from './pipes/vnd.pipe';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { EcoAuthorizeDirective } from '../guard/role.guard';
import { OnlyNumberDirective } from './directives/numeric.directive';
import { DisplayImagePipe } from './pipes/displayImage';
import { DisplayImageResidentPipe } from './pipes/display-image-resident.pipe';
import { PhoneNumberCheckDirective } from './directives/phoneNumber.directive';
import { FormatMoneyDirective } from './directives/formatMoney.directive';
import { DisplayTimeWithoutSecond } from './pipes/displayTimeWithoutSecond.pipe';
import { EcoPermisionDirective } from '../guard/permision.guard';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NzIconModule,
    PerfectScrollbarModule,
    SearchPipe,
    VndPipe,
    NzModalModule,
    NzNotificationModule,
    NzSpinModule,
    EcoAuthorizeDirective,
    OnlyNumberDirective,
    DisplayImagePipe,
    DisplayImageResidentPipe,
    PhoneNumberCheckDirective,
    FormatMoneyDirective,
    NgSelectModule,
    DisplayTimeWithoutSecond,
    EcoPermisionDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    NzIconModule,
    NzToolTipModule,
    PerfectScrollbarModule,
    NzModalModule
  ],
  declarations: [
    SearchPipe,
    VndPipe,
    EcoAuthorizeDirective,
    OnlyNumberDirective,
    DisplayImagePipe,
    DisplayImageResidentPipe,
    PhoneNumberCheckDirective,
    FormatMoneyDirective,
    DisplayTimeWithoutSecond,
    EcoPermisionDirective
  ],
  providers: [
    CurrencyPipe,
    ThemeConstantService
  ]
})

export class SharedModule { }
