import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseData } from 'src/app/models/response';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})

export class BdsDonViService extends BaseService {
  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.SERVICE_API_MAIN}api/BdsDonVi`)
  }

  // GetAllByBdsDotBan(idDotBan): Promise<ResponseData> {
  //   const url = `${this.table}/GetAllByBdsDotBan/${idDotBan}`;
  //   return this.get<ResponseData>(url).toPromise();
  // }

  GetPhanTrangDaiLyTheoDk(body) {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDonVi/LayPhanTrangDaiLyTheoDk`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  // Get list id đại lí đã được phân quyền
  GetTatCaDaiLyDaPhanQuyen(body) {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDonVi/LayTatCaIdDaiLyDaPhanQuyen`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  GetTatCaIdDaiLy() {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDonVi/LayTatCaIdDaiLy`;
    return this.get<ResponseData>(url).toPromise();
  }

  LayTatCaIdDaiLyTheoDuAn(body) {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDonVi/LayTatCaIdDaiLyTheoDuAn`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  LayTatCaDaiLy() {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDonVi/LayTatCaDaiLy`;
    return this.get<ResponseData>(url).toPromise();
  }

  timTheoTuKhoa(keyword) {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDonVi/TimTheoTuKhoa?keyword=${keyword}`;
    return this.get<ResponseData>(url);
  }
  timTheoKeyword(keyword) {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDonVi/TimTheoKeyword?keyword=${keyword}`;
    return this.get<ResponseData>(url);
  }

  CapNhatSoVeSuKien(body) {
    const url = `${environment.SERVICE_API_MAIN}api/BdsDonVi/CapNhatSoVeSuKien`;
    return this.post<ResponseData>(url, body).toPromise();
  }

}
