import { QuanLyTheSaleModule } from './pages/quanlythesale/quanlythesale.module';
import { XebusModule } from './pages/xebus/xebus.module';
import { AuthChildrenGuard } from './guard/authChildren.guard';
import { AuthGuard } from './guard/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';

const routes: Routes = [
  {
    path: 'login',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'event',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/event/event.module').then((m) => m.EventModule),
  },
  {
    path: 'dang-ky-ve',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/event-khach/event-khach.module').then((m) => m.EventKhachModule),
  },
  {
    path: 'qlduthuong',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./modules/giaithuong/giaithuong.module').then((m) => m.GiaithuongModule),
  },
  {
    path: 'forgot-password',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'scanner',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/scanner/scanner.module').then((m) => m.ScannerModule),
  },
  {
    path: 'quanlythesale',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/quanlythesale/quanlythesale.module').then((m) => m.QuanLyTheSaleModule),
  },
  {
    path: 'qr/:id',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/qrcode/qrcode.module').then((m) => m.QrcodeModule),
  },
  {
    path: 'xe-bus',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/xebus/xebus.module').then((m) => m.XebusModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule),
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
