<div class="col-lg-12">
    <div class="m-b-10 col-lg-10" style="display: flex;">
        <nz-form-label [nzSpan]="6" nzFor="ma">Tên miền hiện tại: </nz-form-label>
        <nz-form-control [nzSpan]="18">
            <label>
                <a href="https://{{ tenMienHienTai }}" target="_blank">{{ tenMienHienTai }}</a>
            </label>
        </nz-form-control>
    </div>

    <form [formGroup]="formInput">
        <div class="d-md-flex">
            <div class="m-b-10 col-lg-10" style="display: flex;">
                <nz-form-label [nzSpan]="6" nzRequired nzFor="ma">Tên link bán hàng</nz-form-label>
                <nz-form-control [nzSpan]="18" nzErrorTip="Chưa nhập tên link bán hàng">
                    <input nz-input formControlName="ma" type="text">
                </nz-form-control>
            </div>

            <div class="col-lg-2">
                <button nz-button nzType="primary" (click)="onKiemTraTenMien()">
                    <span>Kiểm tra</span>
                </button>
            </div>
        </div>
    </form>

    <div class="d-md-flex">
        <div class="m-b-10 col-lg-10" style="display: flex;">
            <nz-form-label [nzSpan]="6" nzFor="ma">Tên miền</nz-form-label>
            <nz-form-control [nzSpan]="18">
                <nz-table style="margin-top: 12px;" [nzData]="['']" [nzShowPagination]="false" [nzFrontPagination]="false">
                    <tr *ngFor="let data of lstDataDomain">
                        <td style="display: flex;">
                            <input (change)="onCheckboxChange(data)" class="form-check-input checkbox-zoom" [checked]="data.select" type="checkbox" value="" id="flexCheckDefault">
                            &nbsp; &nbsp; <span>{{ data.ten }}</span>
                        </td>
                    </tr>
                </nz-table>
            </nz-form-control>
        </div>
    </div>
</div>

<nz-table [nzData]="['']" [nzShowPagination]="false" [nzFrontPagination]="false">
    <thead>
        <tr>
            <th>Domain</th>
            <th width="80px" class="text-center"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of lstTenMien">
            <td>
                <span>{{ data.link }}</span>
            </td>
            <td class="text-center">
                <ng-container *ngIf="!data.available">
                    <button  [disabled]="true" nzType="primary" nz-button>Đã chọn</button>
                </ng-container>
                <ng-container *ngIf="data.available">
                    <button (click)="onChonTenMien(data.link)" nzType="primary" nz-button>Chọn</button>
                </ng-container>
            </td>
        </tr>
    </tbody>
    <tFoot style="display: none;"></tFoot>
</nz-table>

<div class="text-right m-t-20">
    <button nz-button (click)="onThoat()">Đóng</button>
</div>