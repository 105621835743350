import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResponseData } from '../models/response';
import { Observable, lastValueFrom } from 'rxjs';
import { getOptions } from '../shared/helper/ultility';

export abstract class BaseService {
  table = '';
  _httpClient: HttpClient;
  constructor(httpClient: HttpClient, tableName: string) {
    this.table = tableName;
    this._httpClient = httpClient;
  }

  layTatca(): Promise<ResponseData> {
    const url = `${this.table}/LayTatca`;
    return lastValueFrom(this.get<ResponseData>(url));
  }
  layTatCaDaiLy(): Promise<ResponseData> {
    const url = `${this.table}/LayTatcaDaiLy`;
    return lastValueFrom(this.get<ResponseData>(url));
  }

  create(body): Promise<ResponseData> {
    const url = `${this.table}/ThemMoi`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  update(body): Promise<ResponseData> {
    const url = `${this.table}/CapNhatThongTin`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  getDetail(id): Promise<ResponseData> {
    const url = `${this.table}/TimTheoId/${id}`;
    return this.get<ResponseData>(url).toPromise();
  }

  delete(id): Promise<ResponseData> {
    const url = `${this.table}/Xoa/${id}`;
    return this.post<ResponseData>(url, {}).toPromise();
  }

  timTheoDieuKien(body) {
    const url = `${this.table}/TimTheoDieuKien`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  timTheoMa(ma): Promise<ResponseData> {
    const url = `${this.table}/TimTheoMa?ma=${ma}`;
    return this.get<ResponseData>(url).toPromise();
  }

  duyetNhanVienSale(model): Promise<ResponseData> {
    const url = `${this.table}/NhanVienSaleDuyet`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  danhSachCanDuyet(body): Observable<ResponseData> {
    const url = `${this.table}/DanhSachCanDuyet`;
    return this.post<ResponseData>(url, body);
  }

  post<T>(url: string, body: any) {
    return this._httpClient.post<T>(url, body, { withCredentials: true });
  }

  get<T>(url: string) {
    return this._httpClient.get<T>(url, { withCredentials: true });
  }

  getWithHeaders<T>(url: string, options: {}) {
    return this._httpClient.get<T>(url, {
      withCredentials: true,
      ...options,
    });
  }

  postWithHeaders<T>(url: string, body: any, options: {}) {
    return this._httpClient.post<T>(url, body, {
      withCredentials: true,
      ...options,
    });
  }

  getDownload(url: string) {
    return this._httpClient.get(url, {
      withCredentials: true,
      responseType: 'blob',
    });
  }

  getDownloadRouting(url: string, header: any) {
    return this._httpClient.get(url, {
      withCredentials: true,
      responseType: 'blob',
      ...header,
    });
  }

  postDownload(url: string, body: any) {
    return this._httpClient.post(url, body, {
      withCredentials: true,
      responseType: 'blob',
    });
  }

  postDownloadRouting(url: string, body: any, header: any) {
    return this._httpClient.post(url, body, {
      withCredentials: true,
      responseType: 'blob',
      ...header,
    });
  }

  createWithMaDuAn(body, maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/ThemMoi`;
    return this._httpClient.post<ResponseData>(url, body, options).toPromise();
  }

  updateWithMaDuAn(body, maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/CapNhatThongTin`;
    return this._httpClient.post<ResponseData>(url, body, options).toPromise();
  }

  getDetailWithMaDuAn(id, maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/TimTheoId/${id}`;
    return this._httpClient.get<ResponseData>(url, options).toPromise();
  }

  deleteWithMaDuAn(id, maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/Xoa/${id}`;
    return this._httpClient.post<ResponseData>(url, null, options).toPromise();
  }
  timTheoDieuKienWithMaDuAn(body, maDuAn) {
    const options = getOptions(maDuAn);
    const url = `${this.table}/TimTheoDieuKien`;
    return this._httpClient.post<ResponseData>(url, body, options).toPromise();
  }
  layTatcaWithMaDuAn(maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/LayTatca`;
    return lastValueFrom(this._httpClient.get<ResponseData>(url, options));
  }

  //#region attach headers into request
  createWithHeaders(body, maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/ThemMoi`;
    return this.postWithHeaders<ResponseData>(url, body, options).toPromise();
  }

  updateWithHeaders(body, maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/CapNhatThongTin`;
    return this.postWithHeaders<ResponseData>(url, body, options).toPromise();
  }

  getDetailWithHeaders(id, maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/TimTheoId/${id}`;
    return this.getWithHeaders<ResponseData>(url, options).toPromise();
  }

  deleteWithHeaders(id, maDuAn): Promise<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/Xoa/${id}`;
    return this.postWithHeaders<ResponseData>(url, {}, options).toPromise();
  }

  timTheoDieuKienWithHeaders(body, maDuAn) {
    const options = getOptions(maDuAn);
    const url = `${this.table}/TimTheoDieuKien`;
    return this.postWithHeaders<ResponseData>(url, body, options).toPromise();
  }

  getDownloadWithHeaders(url: string, maDuAn: string) {
    const options = getOptions(maDuAn);
    return this._httpClient.get(url, {
      ...options,
      withCredentials: true,
      responseType: 'blob',
    });
  }

  postDownloadWithHeaders(url: string, body: any, maDuAn: string) {
    const options = getOptions(maDuAn);
    return this._httpClient.post(url, body, {
      ...options,
      withCredentials: true,
      responseType: 'blob',
    });
  }

  danhSachCanDuyetWithHeaders(body, maDuAn: string): Observable<ResponseData> {
    const options = getOptions(maDuAn);
    const url = `${this.table}/DanhSachCanDuyet`;
    return this.postWithHeaders<ResponseData>(url, body, options);
  }

  //#endregion
}
