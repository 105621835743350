import { Component, Renderer2 } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { PageService } from 'src/app/services/core/page.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html'
})

export class SideNavComponent {

  public menuItems: any[]
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;

  constructor(private renderer: Renderer2, private themeService: ThemeConstantService, private pageService: PageService,) { }

  ngOnInit(): void {
    this.loadMenu();
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);

    // console.log(this.menuItems)

  }
  onHover(event, path, id) {
    const location = event.target.getBoundingClientRect();
    const idtext = 'lv3' + path + id
    const lv3 = document.getElementById(idtext)
    // console.log("right: ", location.right)
    // console.log("top: ", location.top)
    // console.log("bottom: ", location.bottom)
    // console.log("height: ", screen.height)
    // var parentHeight = lv3.parentElement.clientHeight;
    // console.log("parentHieght: ", parentHeight)
    if (location.bottom * 2 < window.innerHeight) {
      this.renderer.setStyle(lv3, 'left', location.right + 1 + 'px')
      this.renderer.setStyle(lv3, 'top', location.top + 'px')
    } else {
      this.renderer.setStyle(lv3, 'left', location.right + 1 + 'px')
      this.renderer.setStyle(lv3, 'bottom', window.innerHeight - location.bottom + 'px')
    }

    // const element = document.querySelector('.ps-content')
    // this.renderer.setStyle(element, 'overflow', 'unset')
  }
  outHover(event, path, id) {
    // const element = document.querySelector('.ps-content')
    // this.renderer.setStyle(element, 'overflow', 'scroll')
  }

  loadMenu() {
    this.pageService.layTatcaMenu().then(rs => {
      if (rs.success) {
        this.menuItems = rs.data;
      }
    })
  }

  closeMobileMenu(): void {
    if (window.innerWidth < 992) {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }
}
