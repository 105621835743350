
<div class="luu-y">
  <span style="color:red"> Lưu ý:</span>
  <div>+ Một ký tự chữ hoa</div>
  <div>+ Một ký tự chữ thường</div>
  <div>+ Một ký tự số</div>
  <div>+ Một ký tự đặc biệt trong mật khẩu</div>
  <div>+ Ít nhất 8 ký tự</div>
</div>
<div class="mt-2">
  <form nz-form [formGroup]="formInput">
    <nz-form-item>
      <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="phoneErrorTpl">
        <div style="display: flex; flex-direction: column;">
          <nz-form-label class="text-left" [nzSpan]="24" nzFor="oldPassword" nzRequired>Mật khẩu hiện
            tại</nz-form-label>
          <nz-input-group [nzSuffix]="pricePrefix">
            <input nz-input formControlName="oldPassword" [type]="passwordVisible ? 'text' : 'password'">
          </nz-input-group>
          <ng-template #phoneErrorTpl let-control>
            <ng-container *ngIf="control.hasError('minLength')">Mật khẩu không được ít hơn 6 ký tự</ng-container>
            <ng-container *ngIf="control.hasError('required')">Không được bỏ trống!</ng-container>
          </ng-template>
          <ng-template #pricePrefix>
            <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="onViewPass()"></span>
          </ng-template>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="phoneErrorTpl1">
        <div style="display: flex; flex-direction: column;">
          <nz-form-label class="text-left" [nzSpan]="24" nzFor="newPassword" nzRequired>Mật khẩu mới</nz-form-label>
          <nz-input-group [nzSuffix]="pricePrefix">
            <input nz-input formControlName="newPassword" [type]="passwordVisible ? 'text' : 'password'">
          </nz-input-group>
          <ng-template #phoneErrorTpl1 let-control>
            <ng-container *ngIf="control.hasError('minLength')">Mật khẩu không được ít hơn 6 ký tự</ng-container>
            <ng-container *ngIf="control.hasError('required')">Không được bỏ trống!</ng-container>
          </ng-template>
          <ng-template #pricePrefix>
            <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="onViewPass()"></span>
          </ng-template>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="phoneErrorTpl2">
        <div style="display: flex; flex-direction: column;">
          <nz-form-label class="text-left" [nzSpan]="24" nzFor="confirmPassword" nzRequired>Nhập lại mật khẩu
            mới</nz-form-label>
          <nz-input-group [nzSuffix]="pricePrefix">
            <input nz-input formControlName="confirmPassword" [type]="passwordVisible ? 'text' : 'password'">
          </nz-input-group>
          <ng-template #phoneErrorTpl2 let-control>
            <ng-container *ngIf="control.hasError('minLength')">Mật khẩu không được ít hơn 6 ký tự</ng-container>
            <ng-container *ngIf="control.hasError('required')">Không được bỏ trống!</ng-container>
          </ng-template>
          <ng-template #pricePrefix>
            <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="onViewPass()"></span>
          </ng-template>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row class="register-area">
      <nz-form-control [nzSpan]="14" [nzOffset]="6">
        <button nz-button nzType="primary" class="m-r-10" [disabled]="!formInput.valid" (click)="onSave()">Lưu</button>
        <button nz-button (click)="onThoat()">Thoát</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>